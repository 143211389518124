import { addErrorHandler, getAppStatus, registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";

const sendErrorLog = (message, error) => {
  console.error(message, error);
  const url = new URL(window.location.href);
  const apiPath = url.pathname.split("/").slice(0, 5).join("/");
  fetch(`${apiPath}/post-log`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      level: "error",
      message,
      holdType: "na",
      errorObject: JSON.stringify(error)
    })
  });
};

// addErrorHandler((error) => {
//   const status = getAppStatus(error.appOrParcelName);
//   sendErrorLog("mx-docs root lifecycle failure", { mx: error.appOrParcelName, status });
// });

const routes = constructRoutes(document.querySelector("#layout"));
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});

const layoutEngine = constructLayoutEngine({ routes, applications });
applications.every((app) => {
  try {
    registerApplication(app);
    return true;
  } catch (err) {
    sendErrorLog(`mx-docs root failed to register app ${app?.name}`, err);
    return false;
  }
});
layoutEngine.activate();
start({
  urlRerouteOnly: true,
});
